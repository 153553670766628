import styled from 'styled-components';
import React, { Fragment } from 'react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';

import { H1 } from '@bscom/components/Headers';
import { Spacing } from '@bscom/components/Spacing';
import { desktops } from '@bscom/styling/breakpoints';
import { MAX_PAGE_WIDTH } from '@bscom/constants/widths';
import { Pagination } from '@bscom/components/Pagination';
import { HeadMetaTags } from '@bscom/components/HeadMetaTags';

import {
  PressEntryPreview,
  FeaturedPressEntryPreview,
} from 'components/PressEntryPreview';
import { Grid } from 'components/Grid';
import { fetchData } from 'lib/cfulClient';
import { BaseLayout } from 'layouts/BaseLayout';
import { renderContentBlock } from 'lib/renderers';
import { fetchTopLevelPage } from 'components/TopLevelPage';
import { PRESS_ENTRIES_PER_PAGE } from 'constants/pagination';
import { EntriesFilterBar } from 'components/EntriesFilterBar';

const {
  publicRuntimeConfig: { baseUrl },
} = getConfig();

const FeaturedEntryWrapper = styled.div`
  margin-top: 100px;

  @media (${desktops}) {
    margin-top: 70px;
  }
`;

const SpacedGrid = styled(Grid)`
  margin: 30px 0 50px 0;

  @media (${desktops}) {
    margin: 50px 0 80px 0;
  }
`;

const LargeH1 = styled(H1)`
  ${({ theme }) => `
    font-size: ${theme.fontSizes['2xl']};

    @media (${desktops}) {
      font-size: ${theme.fontSizes['3xl']};
    }
  `}
`;

const Home = ({ page, pressEntries, totalEntries, featuredEntry }) => {
  const router = useRouter();

  const onPageClick = (pageNo) => router.push(`?page=${pageNo}`);

  return (
    <BaseLayout>
      <HeadMetaTags
        metaTitle={page.seo.title || page.title}
        metaNoindex={page.seo.noIndex}
        metaDescription={page.seo.description}
      />
      <EntriesFilterBar />
      <Spacing withXPadding contentWidth={MAX_PAGE_WIDTH}>
        {featuredEntry && (
          <FeaturedEntryWrapper>
            <LargeH1>Latest global news</LargeH1>
            <FeaturedPressEntryPreview entry={featuredEntry} />
          </FeaturedEntryWrapper>
        )}

        <SpacedGrid noOfColumns={3}>
          {pressEntries.map((pressEntry) => (
            <PressEntryPreview key={pressEntry.sys.id} entry={pressEntry} />
          ))}
        </SpacedGrid>

        {totalEntries > PRESS_ENTRIES_PER_PAGE && (
          <Spacing spaces={[{ bottom: 80 }]}>
            <Pagination
              total={totalEntries}
              pageSize={PRESS_ENTRIES_PER_PAGE}
              pageCurrent={Number(router.query.page) || 1}
              pageLimit={6}
              onPageClick={onPageClick}
            />
          </Spacing>
        )}
      </Spacing>
      {page?.content?.contentCollection?.items?.map((content, index) => (
        <Fragment key={index}>{renderContentBlock(content)}</Fragment>
      ))}
    </BaseLayout>
  );
};

export const getServerSideProps = async (ctx) => {
  const pageData = await fetchTopLevelPage({ ctx, slug: 'home' });

  const page = Number(ctx.query.page);
  const skip = page > 1 ? (page - 1) * PRESS_ENTRIES_PER_PAGE : 0;

  const featuredEntryResult = await fetchData({
    contentType: 'page',
    queries: {
      skip,
      'fields.content.sys.contentType.sys.id': 'pressEntry',
      'fields.content.fields.isFeatured': true,
    },
  });

  const result = await fetchData({
    contentType: 'page',
    queries: {
      skip,
      'fields.content.sys.contentType.sys.id': 'pressEntry',
      'fields.content.fields.isFeatured[nin]': true,
      'fields.content.fields.hideFromResults[nin]': true,
      order: '-fields.date',
    },
    limit: PRESS_ENTRIES_PER_PAGE,
  });

  const featuredEntry = featuredEntryResult.itemsCollection.items[0];

  return {
    props: {
      ...pageData.props,
      totalEntries: result.total,
      articlesSkipped: result.skip,
      pressEntries: result.itemsCollection.items,
      ...(featuredEntry ? { featuredEntry } : undefined),
    },
  };
};

export default Home;
