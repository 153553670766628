import React from 'react';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';

const {
  publicRuntimeConfig: { isProduction },
} = getConfig();

const DynamicModules = {
  entityGrouping: dynamic(() =>
    import(
      /* webpackChunkName: 'EntityGrouping' */
      'components/EntityGrouping'
    )
  ),
  headline: dynamic(
    import(
      /* webpackChunkName: 'Headline' */
      'components/Headline'
    )
  ),
  subscribeForm: dynamic(
    import(
      // webpachChunkName: 'SubscribeForm'
      'components/SubscribeForm'
    )
  )
};

export const renderContentBlockWithAuxProps =
  (auxProps = {}) =>
  (contentBlock) => {
    try {
      const DynamicComponent =
        DynamicModules[contentBlock.sys.contentType.sys.id];

      if (!DynamicComponent) {
        return <div>{`${contentBlock.sys.contentType.sys.id} Missing!!!`}</div>;
      }

      DynamicComponent.displayName = contentBlock.sys.contentType.sys.id;

      return (
        DynamicComponent && <DynamicComponent {...contentBlock} {...auxProps} />
      );
    } catch (e) {
      if (isProduction) {
        throw e;
      } else {
        console.error(e);
        return (
          <span>
            There is no component registered for{' '}
            {contentBlock.sys.contentType.sys.id}, please add an entry in
            src/lib/renderers.js
          </span>
        );
      }
    }
  };

export const renderContentBlock = renderContentBlockWithAuxProps();
